import React from 'react';
import { Check, ChevronRight, Info } from 'lucide-react';
import { motion } from 'framer-motion';
import { css } from '@emotion/css';
// import data from './assets/data.png';

export const HomePage: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: '#f7f7f7',
        display: 'flex',
        justifyContent: 'center',
        paddingRight: 40,
        paddingLeft: 40,
      }}
    >
      <p style={{ fontSize: 16, top: 30, left: 30, position: 'fixed', opacity: 0.4 }}>Superpower</p>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{
          width: 600,
          maxWidth: '100%',
          paddingTop: 100,
          paddingBottom: 200,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ fontSize: 24, marginBottom: 40 }}>Good morning, Jacob</p>
        </div>
        <ResultsCard />
        <p style={{ marginLeft: 20, opacity: 0.3, marginBottom: 20, marginTop: 20, fontSize: 14, color: 'black' }}>
          Updates
        </p>
        <AppointmentCard />
        <ProcessingCard />
        <ScheduleCard />
      </motion.div>
      <NavBar />
    </div>
  );
};

export const AppointmentCard: React.FC = () => {
  return (
    <div
      className={css`
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
      `}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
        width: '100%',
        borderRadius: 16,
        marginBottom: 10,
      }}
    >
      <div style={{ padding: 12 }}>
        <p style={{ fontSize: 24 }}>Upcoming Blood Test</p>
        <p style={{ fontSize: 14, marginTop: 4, color: '#5C5C5C' }}>Wed, Oct 25, 2023 9:20 AM</p>
        <p style={{ fontSize: 14, color: '#A4A4A4' }}>Add to Calendar</p>
      </div>
      <div
        style={{
          width: 130,
          height: 130,
          borderRadius: 10,
          backgroundColor: '#f7f7f7',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: 10,
            height: 10,
            top: 40,
            left: 40,
            borderRadius: 100,
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 200,
          }}
        />
        <img style={{ height: '100%', width: '100%' }} src={'/img/home/map.png'} />
      </div>
    </div>
  );
};

export const ProcessingCard: React.FC = () => {
  return (
    <div
      className={css`
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
      `}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 28,
        width: '100%',
        borderRadius: 16,
        marginBottom: 10,
      }}
    >
      <p style={{ fontSize: 24 }}>Results are Processing</p>
      <p style={{ fontSize: 14, marginTop: 4, color: '#A4A4A4', marginBottom: 20 }}>
        Some results are in, some others typically take a few days to complete
      </p>
      <ProgressIndicator darkMode={false} />
    </div>
  );
};

export const ResultsCard: React.FC = () => {
  return (
    <div
      className={css`
        background-color: #18181b;
        cursor: pointer;
        transition: 0.2s;
        transform: scale(1);
        &:hover {
          transform: scale(0.99);
        }
      `}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: 16,
        marginBottom: 10,
      }}
    >
      <div style={{ padding: 28, borderBottomWidth: 1, borderBottomColor: '#2D2D30' }}>
        <p style={{ fontSize: 24, color: 'white' }}>Some results are in</p>
        <p style={{ fontSize: 14, marginTop: 4, color: '#A4A4A4', lineHeight: 1, maxWidth: '80%' }}>
          Some of your results from the blood test on Thursday are available, it’s time to view your biomarkers.
        </p>
        <div style={{ marginTop: 30 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p style={{ color: 'white', fontSize: 24, marginRight: 10, width: 200 }}>22 optimal</p>
            <div style={{ width: 280, height: 10, backgroundColor: '#00FCA1', borderRadius: 8 }}></div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -6 }}>
            <p style={{ color: 'white', fontSize: 24, marginRight: 10, width: 200 }}>6 not optimal</p>
            <div
              style={{
                width: 90,
                height: 10,
                backgroundColor: '#FF68DE',
                borderRadius: 8,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div style={{ padding: 28 }}>
        <ProgressIndicator darkMode={true} />
      </div>
    </div>
  );
};

export const ScheduleCard: React.FC = () => {
  const services = [
    {
      title: 'Basic Blood Panel',
      image: '/img/home/blood1.png',
      description: '80+ biomarkers covering your entire body, bookable in your Superpower dashboard',
      type: 'protocol',
    },

    {
      title: 'Extended Blood Panel',
      image: '/img/home/blood2.png',
      description: '120+ biomarkers covering your entire body, bookable in your Superpower dashboard',
      type: 'membership',
    },
    {
      title: 'Long Term Blood Analysis',
      image: '/img/home/blood3.png',
      description: '90+ biomarkers covering your entire body, bookable in your Superpower dashboard',
      type: 'recommended',
    },
  ];
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 28,
        width: '100%',
        borderRadius: 16,
        marginBottom: 10,
      }}
    >
      <p style={{ fontSize: 24, marginBottom: 20 }}>Schedule a Service</p>
      {services.map((service) => {
        return <ServiceCell key={service.title} service={service} />;
      })}
    </div>
  );
};

const NavBar: React.FC = () => {
  const options = [
    { name: 'Home', icon: '/img/home/home.png' },
    { name: 'Services', icon: '/img/home/services.png' },
    { name: 'Data', icon: '/img/home/data.png' },
    { name: 'Products', icon: '/img/home/shop.png' },
  ];

  return (
    <div
      style={{
        padding: 4,
        backgroundColor: 'black',
        borderRadius: 200,
        boxShadow: '0px 10px 60px -1px rgba(0,0,0,0.3)',
        position: 'fixed',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 200,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {options.map((o, i) => {
        const selected = i === 0;
        return (
          <div
            key={i}
            style={{
              paddingLeft: 16,
              paddingRight: 22,
              paddingTop: 14,
              paddingBottom: 14,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: selected ? 'rgba(255,255,255,0.1)' : undefined,
              borderRadius: 100,
              opacity: selected ? 1 : 0.6,
            }}
          >
            <img src={o.icon} style={{ height: 16, width: 16, marginRight: 8 }} />
            <p style={{ color: 'white', fontSize: 14, marginTop: 1 }}>{o.name}</p>
          </div>
        );
      })}
    </div>
  );
};

const ServiceCell: React.FC<{ service: { title: string; description: string; type: string; image: string } }> = ({
  service,
}) => {
  return (
    <div
      className={css`
        border-color: #d9d9d9;
        background-color: white;
        transition: 0.2s;
        border-width: 1px;
        cursor: pointer;
        &:hover {
          background-color: #f7f7f7;
          /* border-color: white; */
        }
      `}
      style={{
        width: '100%',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 20,
        marginBottom: 10,
        alignItems: 'flex-start',
      }}
    >
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <img
          src={service.image}
          style={{
            width: 100,
            height: 100,
            backgroundColor: '#f7f7f7',
            borderRadius: 10,
            objectFit: 'cover',
            paddingTop: -2,
          }}
        />
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', flexDirection: 'column', paddingLeft: 20 }}>
          <p style={{ fontSize: 20 }}>{service.title}</p>
          <p style={{ color: '#8D8D8D', fontSize: 14 }}>{service.description}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ChevronRight size={18} />
        </div>
      </div>
      {(service.type === 'protocol' || service.type === 'recommended') && (
        <div style={{ display: 'flex', flexDirection: 'row', opacity: 0.4, alignItems: 'center', marginTop: 14 }}>
          <Info size={18} style={{ marginRight: 10 }} />
          <p style={{ fontSize: 14, marginTop: 2 }}>
            {service.type === 'protocol' ? 'Part of your gut optimization protocol' : 'Recommended'}
          </p>
        </div>
      )}
      {service.type === 'membership' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 14,
            backgroundColor: 'rgba(0,0,0,0.1)',
            paddingTop: 8,
            paddingBottom: 8,
            paddingRight: 20,
            paddingLeft: 12,
            borderRadius: 100,
            opacity: 0.5,
          }}
        >
          <Check size={16} />
          <p style={{ fontSize: 14, marginLeft: 6 }}>Free as part of your Superpower membership</p>
        </div>
      )}
    </div>
  );
};

const ProgressIndicator: React.FC<{
  darkMode: boolean;
}> = ({ darkMode }) => {
  const style = {
    textColor: darkMode ? 'white' : 'black',
    pillColorIncomplete: darkMode ? '#2C2C2C' : '#f7f7f7',
    subtractionColor: darkMode ? '#18181B' : 'white',
    greenBackground: darkMode ? '#17312A' : '#E1F2EC',
  };
  const tests = [
    { name: 'Core', complete: true },
    { name: 'Hormones', complete: false },
    { name: 'Nutrition', complete: false },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
      {tests.map((test, i) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 50,
                backgroundColor: test.complete ? style.greenBackground : style.pillColorIncomplete,
                padding: 4,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                zIndex: 2,
              }}
            >
              {test.complete ? (
                <div
                  style={{
                    height: 26,
                    width: 26,
                    backgroundColor: '#11C182',
                    borderRadius: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Check style={{ color: '#E1F2EC' }} size={16} />
                </div>
              ) : (
                <motion.div
                  initial={{ transform: 'rotate(0deg)' }}
                  animate={{ transform: 'rotate(360deg)' }}
                  transition={{ repeatType: 'loop', repeat: Infinity, duration: 2, ease: 'linear' }}
                  style={{
                    height: 26,
                    width: 26,
                    borderRadius: 200,
                    borderColor: 'rgba(150,150,150,0.3)',
                    borderTopColor: 'rgba(150,150,150,0.6)',
                    borderWidth: 1,
                  }}
                />
              )}
              <p style={{ fontSize: 14, marginRight: 16, marginLeft: 10, color: style.textColor }}>{test.name}</p>
            </div>
            {i !== tests.length - 1 && (
              <div
                style={{
                  background: `linear-gradient(to right, ${
                    test.complete ? style.greenBackground : style.pillColorIncomplete
                  }, ${tests[i + 1].complete ? style.greenBackground : style.pillColorIncomplete})`,
                  height: 14,
                  width: 24,
                  marginRight: -5,
                  marginLeft: -5,
                  zIndex: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: 18,
                    height: 18,
                    backgroundColor: style.subtractionColor,
                    top: -12,
                    borderRadius: 100,
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    width: 18,
                    height: 18,
                    backgroundColor: style.subtractionColor,
                    bottom: -12,
                    borderRadius: 100,
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
