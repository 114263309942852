import { createGlobalStyle } from 'styled-components';

import NbInternationalProboldWoff from './fonts/nbinternationalprobol-webfont.woff';
import NbInternationalProboldWoff2 from './fonts/nbinternationalprobol-webfont.woff2';
import NbInternationalProbooWoff from './fonts/nbinternationalproboo-webfont.woff';
import NbInternationalProbooWoff2 from './fonts/nbinternationalproboo-webfont.woff2';
import NbInternationalProbolitaWoff from './fonts/nbinternationalprobolita-webfont.woff';
import NbInternationalProbolitaWoff2 from './fonts/nbinternationalprobolita-webfont.woff2';
import NbInternationalProregularWoff from './fonts/nbinternationalproreg-webfont.woff';
import NbInternationalProregularWoff2 from './fonts/nbinternationalproreg-webfont.woff2';
import NbInternationalPromonoWoff from './fonts/nbinternationalpromono-webfont.woff';
import NbInternationalPromonoWoff2 from './fonts/nbinternationalpromono-webfont.woff2';

export const FontStyle = createGlobalStyle`@font-face {
    font-family: 'nb_international_probold';
    src: 
         url('${NbInternationalProboldWoff2}') format('woff2'),
         url('${NbInternationalProboldWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nb_international_probook';
    src: 
         url('${NbInternationalProbooWoff2}') format('woff2'),
         url('${NbInternationalProbooWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nb_international_proBdIt';
    src: 
         url('${NbInternationalProbolitaWoff2}') format('woff2'),
         url('${NbInternationalProbolitaWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nb_international_proregular';
    src: 
         url('${NbInternationalProregularWoff2}') format('woff2'),
         url('${NbInternationalProregularWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nb_international_promono';
    src: 
         url('${NbInternationalPromonoWoff2}') format('woff2'),
         url('${NbInternationalPromonoWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nb_international_proMdIt';
    src: url('nbinternationalpromedita-webfont.eot');
    src: url('nbinternationalpromedita-webfont.eot?#iefix') format('embedded-opentype'),
         url('nbinternationalpromedita-webfont.woff2') format('woff2'),
         url('nbinternationalpromedita-webfont.woff') format('woff'),
         url('nbinternationalpromedita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nb_international_promedium';
    src: url('nbinternationalpromed-webfont.eot');
    src: url('nbinternationalpromed-webfont.eot?#iefix') format('embedded-opentype'),
         url('nbinternationalpromed-webfont.woff2') format('woff2'),
         url('nbinternationalpromed-webfont.woff') format('woff'),
         url('nbinternationalpromed-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nb_international_proLtIt';
    src: url('nbinternationalproligita-webfont.eot');
    src: url('nbinternationalproligita-webfont.eot?#iefix') format('embedded-opentype'),
         url('nbinternationalproligita-webfont.woff2') format('woff2'),
         url('nbinternationalproligita-webfont.woff') format('woff'),
         url('nbinternationalproligita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nb_international_prolight';
    src: url('nbinternationalprolig-webfont.eot');
    src: url('nbinternationalprolig-webfont.eot?#iefix') format('embedded-opentype'),
         url('nbinternationalprolig-webfont.woff2') format('woff2'),
         url('nbinternationalprolig-webfont.woff') format('woff'),
         url('nbinternationalprolig-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nb_international_proitalic';
    src: url('nbinternationalproita-webfont.eot');
    src: url('nbinternationalproita-webfont.eot?#iefix') format('embedded-opentype'),
         url('nbinternationalproita-webfont.woff2') format('woff2'),
         url('nbinternationalproita-webfont.woff') format('woff'),
         url('nbinternationalproita-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

html, body {
  font-family: 'nb_international_proregular';
  -webkit-font-smoothing: antialiased;
}

.light {
  font-family: 'nb_international_probook';
}`;
