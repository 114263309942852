import React, { useEffect } from 'react';
import { Home, LayoutGrid, LineChart, Lock, Store } from 'lucide-react';
import { FontStyle } from './fonts';
import { AppShell, Logo, Loading, useSuperpower } from '@superpower/nightfall';
import { AppRoutes } from './AppRoutes';
import { useLocation } from 'react-router-dom';
import './App.css';
import { featureGate } from './config';
import { HomePage } from './pages/homePagePrototype/HomePage';

export function App(): JSX.Element {
  const superpower = useSuperpower();
  const profile = superpower.getProfile();

  // Hack: Prevents zoom in on mobile input
  useEffect(() => {
    window.addEventListener('load', (_) => {
      if (navigator.userAgent.indexOf('iPhone') > -1) {
        document
          ?.querySelector('[name=viewport]')
          ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1');
      }
    });
  });

  const { pathname } = useLocation();

  // if (superpower.isLoading()) {
  //   return (
  //     <div className="flex min-h-screen flex-grow">
  //       <Loading />
  //     </div>
  //   );
  // }

  const disabled =
    !profile ||
    profile.onboarding?.status === 'INCOMPLETE' ||
    profile.initialResults?.status === 'INCOMPLETE' ||
    pathname.startsWith('/health') ||
    /^\/orders\/[A-Za-z0-9-]+$/.test(pathname);

  const main = [
    {
      icon: <LayoutGrid />,
      label: 'Services',
      href: '/services',
    },
    {
      icon: <LineChart />,
      label: 'Data',
      href: '/biomarkers',
    },
    {
      icon: <Store />,
      label: 'Products',
      href: '#',
      onClick: () => {
        window.open('https://products.superpower.com', '_blank');
      },
    },
  ];

  if (!featureGate.homePage) {
    main.unshift({
      icon: <Home />,
      label: 'Home',
      href: '/',
    });
  }

  if (profile?.admin) {
    main.push({
      icon: <Lock />,
      label: 'Admin',
      href: '/admin',
    });
  }

  return (
    <>
      <FontStyle />
      {/* <AppShell
        disabled={disabled}
        logo={<Logo />}
        pathName={pathname}
        main={main}
        settings={[
          {
            label: 'Text us',
            href: '#',
            onClick: () => {
              window.open('sms:+16508443123');
            },
          },
          {
            label: 'Settings',
            href: '/settings/profile',
          },
        ]}
      > */}
      {/* <AppRoutes /> */}
      {/* </AppShell> */}
      <HomePage />
    </>
  );
}
