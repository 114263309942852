export interface SuperpowerAppConfig {
  baseUrl: string;
  issuer: string;
  calendlyToken: string;
  calendlyUrl: string;
  registerEnabled: boolean;
  stripePublishableKey: string;
}

const config: SuperpowerAppConfig = {
  baseUrl: process.env.REACT_APP_SUPERPOWER_BASE_URL as string,
  registerEnabled: true,
  issuer: process.env.REACT_APP_SUPERPOWER_ISSUER || 'https://localhost:3001',
  calendlyToken: process.env.REACT_APP_CALENDLY_TOKEN as string,
  calendlyUrl: process.env.REACT_APP_CALENDLY_URL as string,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
};

export function getConfig(): SuperpowerAppConfig {
  return config;
}

// Temporary
export const featureGate = {
  homePage: true,
};
